import { ComponentType, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { RouterLink } from 'components/Link';
import { useUserPostAListingHref } from 'store/hooks/useUserPostAListingHref';

type Props = {
  children: ReactNode;
  className?: string;
  component?: ComponentType<{
    children: ReactNode;
    onClick?: () => void;
    to:
      | {
          pathname: string;
          state: {
            from: string;
          };
        }
      | string;
    from?: string;
    'data-qa-id'?: string;
  }>;
  onClick?: () => void;
  'data-qa-id'?: string;
};

export function PostListingLink({
  children,
  className,
  component,
  'data-qa-id': qaId,
  onClick,
}: Props) {
  const location = useLocation();
  const Component = component || RouterLink;

  const postALinkHref = useUserPostAListingHref();

  return (
    <Component
      className={className}
      to={postALinkHref}
      state={{
        from: location.pathname,
      }}
      from={location.pathname}
      data-qa-id={qaId || 'post-a-listing'}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}
